<template>
<div>
  <div class="column">
    <div class="col">
  <q-input v-model="form" lazy-rules filled ref="input"
           :rules="[ val => val.length <= lmax || this.template_text.USEMAX+' '+lmax+' '+this.template_text.CHARACTERS,
                        val => val.length >= lmin || this.template_text.USEMIN+' '+lmin+' '+this.template_text.CHARACTERS ,
                         isMandatory]"

  />
    </div>
  <div class="col self-end" v-if="dnext || forcenext">
  <q-btn color="secondary"  push icon-right="navigate_next" :label="template_text.NEXT" @click="onNext"/>
  </div>
  </div>
</div>
</template>

<script>
export default {
  watch: {
    form: {
      handler: function(newValue) {
        this.$emit('input', newValue);
      },
      deep: true,
    }
  },

  name: "string_input",
  data(){
    return {
      form:this.value||"",
      lmin:this.attribut.lmin||0,
      lmax:this.attribut.lmax||100,
    }

  },
  computed:{
  },
  props:{
    value:String,
    attribut:Object,
    forcenext:Boolean,
    dnext:Boolean,
    template_text:Object,
    mandatory:Boolean,
    language:String,
  },
methods:{
    async validate(){
      return await this.$refs.input.validate()
    },
    isMandatory(val){
      if(this.mandatory){
        return !!val || this.template_text.MANDATORYFIELD
      }
      return true
    },
    async onNext(){
      if(await this.validate()) this.$emit('next');

    }
},
}
</script>

<style scoped>

</style>
